import '../index.css'; 
import Navbar from './navbar';
import { Helmet } from 'react-helmet';
import Footer from './footer';

export default function recJudi(){
    return (
        <>
            <Helmet>
                <title>Recouvrement judiciaire - Recouv Management</title>
                <meta name="og:title" property="og:title" content="Recouvrement judiciaire - Recouv Management"></meta>
                <meta name="description" content="Les tentatives de recouvrement amiable n’ont rien données ? Vous souhaitez garantir vos créances et maintenir la pression sur vos débiteurs ?" />
                <meta name="keywords" content="recouvrement de créances, société de recouvrement, solutions efficaces, professionnalisme, équipe expérimentée, intégrité, transparence, respect, service de qualité, recherches et enquêtes, recouvrement judiciaire, recouvrement à l'amiable, objectifs financiers" />
            </Helmet>

              <Navbar/>

            <div className='container banner-card' >
                <img src="./judi.jpg"   alt="."/>
                <div className=' banner-text'>
                    <h1 className='container h1ban text-start'>RECOUVEREMENT JUDICIAIRE</h1>
                    <p className='container pban fw-lighter'>Les tentatives de recouvrement amiable n’ont rien données ?</p>
                <p className='container pban fw-lighter'> Vous souhaitez garantir vos créances et maintenir la pression sur vos débiteurs ?</p>
                <p className='container pban fw-lighter'> Vous souhaitez assigner vos débiteurs en justice tout en maitrisant vos coûts et assurer 
                    un suivi permanant du dossier jusqu’à son aboutissement final ?</p>
                </div>

            </div>
            
            {/* bg-warning-subtle */}
            <div className=' REC container'>
                <div className='row '>
                    <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-6'>
                        <p className='pjudi fw-lighter'>Avant d’entamer toute procédure judiciaire, nous 
                            effectuons des investigations poussées sur la 
                            solvabilité de vos débiteurs, leur localité ainsi que 
                            toutes les informations permettant de sécuriser vos 
                            créances.
                            En collaboration avec nos avocats partenaires, nous 
                            choisissons la procédure la mieux adaptée selon le 
                            montant de la créance et les garanties détenues et la 
                            solvabilité de votre client débiteur.
                            Nous bénéficions d’un partenariat avec un large 
                            réseau d’huissiers de justice sur l’ensemble du 
                            royaume afin de délivrer les mises en demeures et 
                            notifications pour un suivi précis des mesures 
                            d’exécutions.
                            Tout au long de la procédure judiciaire, nous 
                            maintenons les négociations avec les débiteurs et 
                            privilégions un arrangement amiable.</p>
                    </div >
                    <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-6 backy text-end'>
                    
                    <img src="./logoWhite.png" class="img-fluid rounded imglogo"  alt="..."/>
                    </div >   
                </div>
            </div>

            <Footer />
        </>

        
    )
}