/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.css';
import '../footer.css';
import { Link } from "react-router-dom";


const Footer = () => {
  return (
    <footer className="footer-section">
      <div className="container">
        <div className="footer-cta pt-5 pb-5">
          <div className="row">
            <div className="col-xl-4 col-md-4 mb-30">
              <div className="single-cta">
                <i className="fas fa-map-marker-alt"></i>
                <div className="cta-text">
                  <span>Settapark, Parc Industriel Bureau N°1, 2ème étage</span>
                  <br/>
                  <span>Settat, Maroc</span>

                </div>
              </div>
            </div>
            <div className="col-xl-4 col-md-4 mb-30">
              <div className="single-cta">
                <i className="fas fa-phone"></i>
                <div className="cta-text">
                  <span><a href='tel:+212661813528'>+212 661 813 528</a> / <a href='tel:+212523718520'>+212 523 718 520</a></span>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-md-4 mb-30">
              <div className="single-cta">
                <i id='mail' className="far fa-envelope-open"></i>
                <div className="cta-text">
                  <span><a href='mailto:recouvmanagement@gmail.com'>recouvmanagement@gmail.com</a></span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-content pt-5 pb-5">
          <div className="row">
            <div className="col-xl-4 col-lg-4 mb-50">
              <div className="footer-widget">
                <div className="footer-logo">
                  <a href="#"><img src="https://i.ibb.co/6NTJPrH/MOT-SITE-WEB-orange-bla-ktba.png" className="img-fluid" alt="logo" /></a>
                </div>
                <div className="footer-text">
                  <p>RECOUV MANAGEMENT est là pour résoudre vos problèmes en recouvrant vos CRÉANCES.</p>
                </div>
                <div className="footer-social-icon">
                  <span>Suivez-nous</span>
                  <a href="http://wa.me/+212661813528" target='_blank' rel='noreferrer'><i className="fab fa-whatsapp whatsapp-bg"></i></a>
                  <a href="https://www.instagram.com/recouv_management/" target='_blank' rel='noreferrer'><i className="fab fa-instagram instagram-bg"></i></a>
                  <a href="https://www.linkedin.com/in/recouv-management" target='_blank' rel='noreferrer'><i className="fab fa-linkedin linkedin-bg"></i></a>
                  <a href="https://twitter.com/RecouvM" target='_blank' rel='noreferrer'><i className="fab fa-twitter twitter-bg"></i></a>
                </div>
                
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6 mb-30">
              <div className="footer-widget">
                <div className="footer-widget-heading">
                  <h3>Liens utiles</h3>
                </div>
                <ul>
                  <li><Link class="nav-linkk " aria-current="page" to={"/"}>Accueil</Link></li>
                  <li><Link class="nav-linkk " aria-current="page" to={"/Enquetes"}>Recherches et enquetes</Link></li>
                  <li><Link class="nav-linkk " to={"/AboutUs"}>À propos de nous</Link></li>
                  <li><Link class="nav-linkk " to={"/Judiciare"}>Recouvrement judiciaire</Link></li>
                  <li><Link class="nav-linkk " to={"/Contact"}>Contact</Link></li>
                  <li><Link class="nav-linkk " to={"/Amiable"}>Recouvrement a l'amiable</Link></li>
                </ul>
              </div>
            </div>

          </div>
        </div>
      </div>
      <div className="copyright-area">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-6 text-center text-lg-left">
              <div className="copyright-text">
                <p>&copy; {new Date().getFullYear()} Copyright {' '} <a href="#">RecouvManagement.</a> All Right Reserved </p>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 d-none d-lg-block text-right">
              <div className="footer-menu">
                <ul>
                  <li><Link class="nav-linkk " aria-current="page" to={"/"}>Accueil</Link></li>
                  <li><Link class="nav-linkk " to={"/AboutUs"}>À propos de nous</Link></li>
                  <li><Link class="nav-linkk " to={"/Contact"}>Contact</Link></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
