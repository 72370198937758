import '../index.css'; 
import { Link } from 'react-router-dom';

export default function navbar(){
    return (
        <>
            <nav class="navbar navbar-expand-lg ">
            <div class="container-fluid">
            <img src="./logo.png" alt="Logo" width="80" height="70" class="d-inline-block align-text-top" />
                <button class="navbar-toggler focus-ring focus-ring-warning" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse " id="navbarNavAltMarkup">
                <div class="navbar-nav  ">
                <Link class="nav-link " aria-current="page" to={"/"}>Accueil</Link>
                <Link class="nav-link " to={"/AboutUs"}>À propos de nous</Link>
                <Link class="nav-link " to={"/Contact"}>Contact</Link>
                    
                    
                </div>
                </div>
            </div>
            </nav>
        </>
    )
}