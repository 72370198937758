import '../index.css'
import { Link } from 'react-router-dom'

export default function services(){
    return (
        <>
            <div className='container' id='ser'>
                <div className='row row33'>
                    <h1 className='text-center h1srv'>SERVICES</h1>
                    <div className='col-md-4 col-lg-4 col-xl-4 col-xxl-4'>
                        <div class="card cards" >
                        <div class="card-body">
                            <h5 class="card-title titlt5">RECHERCHES ET ENQUETES</h5>
                            
                            <p class="card-text"> L’ENQUETE « EMPLOYEUR »
                            Localisation de l’employeur ou d’un 
                            organisme payeur source de revenus, dans le 
                            cas où il y a une activité professionnelle ou 
                            non.</p>
                            <Link to={"/enquetes"}>
                            <button className='tosrv'>À propos de nous</button>
                            </Link>
                            
                        </div>
                        </div>
                    </div>

                    <div className='col-md-4 col-lg-4 col-xl-4 col-xxl-4'>
                    <div class="card cards" >
                        <div class="card-body">
                            <h5 class="card-title titlt5">RECOUVEREMENT JUDICIAIRE</h5>
                            
                            <p class="card-text">Avant d’entamer toute procédure judiciaire, nous 
                            effectuons des investigations poussées sur la 
                            solvabilité de vos débiteurs, leur localité ainsi que 
                            toutes les informations permettant de sécuriser vos 
                            créances.</p>
                            <Link to={"/judiciare"}>
                            <button className='tosrv'>À propos de nous</button>
                            </Link>
                            
                        </div>
                        </div>
                    </div>

                    <div className='col-md-4 col-lg-4 col-xl-4 col-xxl-4'>
                    <div class="card cards" >
                        <div class="card-body">
                            <h5 class="card-title titlt5">RECOUVREMENT À L’AMIABLE</h5>
                            
                            <p class="card-text">RECOUV MANAGEMENT privilège les actions de recouvrement à l’amiable pour 
                            optimiser le règlement des créances en souffrance en évitant un recours devant 
                            les tribunaux à l’avantages des personnes physiques et morales.</p>
                            <Link to={"/amiable"}>
                            <button className='tosrv'>À propos de nous</button>
                            </Link>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}