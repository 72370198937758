import React from "react";
import "../clients.css";


class Clients extends React.Component {
    render() {
      return (
        <>
        <div className="clt">
          <h1 className="text-center tit">NOS CLIENTS</h1>
          <p className="text-center fw-lighter">ILS NOUS FONT CONFIANCE</p>

        </div>
        <div className="slider">
          <div className="slide-track">
            {this.props.slides.map((slide, index) => (
              <div key={index} className="slide">
                <img src={slide.url} height="100" width="250" alt={slide.alt} />
              </div>
            ))}
          </div>
        </div>
        </>
      );
    }
  }
  
  export default Clients;