import '../index.css'; 
import { Helmet } from 'react-helmet';
import Navbar from './navbar'
import Footer from './footer'

export default function aboutUs(){
    return (
        <>
            <Helmet>
                <title>À propos de nous - Recouv Management</title>
                <meta name="og:title" property="og:title" content="À propos de nous - Recouv Management" />
                <meta name="description" content="C'est avec grand plaisir que je vous accueille sur le site web de notre société de recouvrement de créances. Chez RECOUV MANAGEMENT.." />
                <meta name="keywords" content="recouvrement de créances, société de recouvrement, solutions efficaces, professionnalisme, équipe expérimentée, intégrité, transparence, respect, service de qualité, recherches et enquêtes, recouvrement judiciaire, recouvrement à l'amiable, objectifs financiers" />
            </Helmet>
            <Navbar/>

            <h1 className='text-center propos '>À propos de nous</h1>

            <div className='backky mt-5'>
                

                
                <div className='ally'>
                <div className='row row1 '>
                    <div className='col-md-4 col-lg-4 col-xl-4'>
                        <p className='NOTR1'>NOTRE ENGAGEMENT</p>
                        
                    </div>
                    <div className='col-md-6 col-lg-6 col-xl-6'> 
                    <p className='NOTR2 fw-lighter'>C'est avec grand plaisir que je vous accueille sur le site web de notre société de 
                        recouvrement de créances. Chez RECOUV MANAGEMENT, nous comprenons l'importance 
                        cruciale que les créances représentent pour votre entreprise. Nous sommes déterminés à 
                        vous offrir des solutions efficaces et professionnelles pour vous aider à récupérer les fonds 
                        qui vous sont dus.  </p>
                    </div>
                </div>
                </div>

                <div className='ally'>
                <div className='row row3 '>
                    <div className='col-md-4 col-lg-4 col-xl-4 col-xxl-4'>
                        <p className='NOTR3'>NOTRE EQUIPE</p>
                        
                    </div>
                    <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-6'> 
                    <p className='NOTR4 fw-lighter'>Notre équipe expérimentée est spécialisée dans le recouvrement de créances et s'engage à 
                        traiter chaque cas avec la plus grande attention et le plus grand professionnalisme. Nous 
                        comprenons les défis auxquels vous êtes confrontés et nous mettons tout en œuvre pour 
                        vous fournir des solutions adaptées à vos besoins spécifiques. </p>
                    </div>
                </div>
                </div>


                <div className='ally'>
                <div className='row row2 '>
                    <div className='col-md-4 col-lg-4 col-xl-4 col-xxl-4'>
                        <p className='NOTR5'>NOS VALEURS</p>
                        
                    </div>
                    <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-6'> 
                    <p className='NOTR6 fw-lighter'>Chez RECOUV MANAGEMENT, nous croyons en l'intégrité, la transparence et le respect. 
                        Nous nous engageons à agir de manière éthique dans toutes nos interactions avec nos 
                        clients, partenaires et débiteurs. Notre objectif est de vous fournir un service de qualité 
                        supérieure et de vous aider à atteindre vos objectifs financiers. </p>
                    </div>
                </div>
                </div>

                <div className='ally'>
                <div className='row row4 '>
                    <div className='col-md-4 col-lg-4 col-xl-4 col-xxl-4'>
                        <p className='NOTR7'>LE GENERAL MANAGER</p>
                        
                    </div>
                    <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-6'> 
                    <p className='NOTR8 fw-lighter'>Je vous invite à explorer notre site web pour en apprendre davantage sur nos services et 
                            notre approche. N'hésitez pas à nous contacter si vous avez des questions ou si vous 
                            souhaitez discuter de vos besoins en matière de recouvrement de créances. Nous sommes 
                            là pour vous aider </p>
                    </div>
                </div>
                </div>
            </div>

            <Footer/>
        </>
    )
}